* {
    font-family: "Fraunces",serif;
  }
  
  .gold{
    color: #956911;
  }

  .bgGold{
    background-color: #956911;
  }

  .Authform{
    background-color: #ffcbd3;
    padding: 2%;
    margin: auto ;
    width: 60%;
    border-radius: 20px;
    margin-bottom: 5%;

  }

.topnav{
display: block;
}

.title{
    margin: 2% auto;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    color: #956911;
}


.buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.editBtn{
border-radius: 7px;
background-color: #0D6EFD;
/* background-color: #; */
font-weight: 500;
border:1px solid transparent;
box-shadow: 0px 3px 5px rgb(0, 0, 0, 0.1);
width: 45%;
padding: 1.5%;
color: white;
}

.side{
    width: 25%;
}
.deleteBtn{
    border-radius: 7px;
    background-color: #DC3545;
    font-weight: 500;
    border:1px solid transparent;
    box-shadow: 0px 3px 5px rgb(0, 0, 0, 0.1);
    width: 45%;
    padding: 1.5%;
    color: white;

}
.wishlisticon, .carticon {
  display: block; /* Default display block */
}
.accepted , .rejected{
    padding: 2%;
    border-radius: 10px;
    font-weight: bold;
    width: 60%;
    margin: 0 2%;
}

.rejected{
    background-color: #ffcbd3;
    border: 1px solid #ffcbd3 ;
    color: black;
}

.accepted {
    background-color: rgb(27, 117, 47);
    border: 1px solid rgb(27, 117, 47) ;
    color: white;
}

/* .deleteBtn:hover{
        background-color: rgb(218, 12, 12);
        border:1px solid rgb(218, 12, 12);
        color: white;

    } */

    .Btn{
        display: flex;
        justify-content: center; 
        width: 100%;
        align-items: center; 
        margin: 2% 0;
    }

.addBtn{
    border-radius: 7px;
    background-color: #ffcbd3;
    font-weight: bold;
    width: 50%;
    border:1px solid transparent;
    box-shadow: 0px 3px 5px rgb(0, 0, 0, 0.1);
    padding: 1%;
    color: black;
}

.search-icon{
    display: none!important;
}

.sidebar{
    display: none;
}

.logo{
    display: block;
}

.bottom-navbar{
    display: none;
}

.sizebtn{
    border-radius: 50%;
    padding: 0.25rem 0.5rem;
    border: 2px #956911 solid;
    color: #956911;
}

.selected {
    background-color: #956911; /* Highlight selected size */
    color: white;
  }
 
  .responsive{
    margin-top: 10%;
  }

  @media  (max-width: 1200px) {
    /* .buttons{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  } */

    .responsive{
      margin-top: 13%;
    }

  }

  @media  (max-width: 992px) {

    .responsive{
      margin-top: 17%;
    }

  }

@media (max-width: 768px) {

  .wishlisticon, .carticon {
    display: none !important; /* Hide when screen width is 768px or less */
  }

  .responsive{
    margin-top: 14%;
  }
  
    .search-icon{
        display: inline-block!important;
    }

    .sidebar{
        display: block;
    }
    /* .logo{
        display: none;
    } */
  
    .bottom-navbar{
        display: block;
    }
    .topnav{
        display: none;
        }
        .side{
            width: 30%;
        }
        
}
@media (max-width: 400px) {
    .side{
        width: 50%;
    }
}


.Loading{
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 9999;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
 }


 /* NotFound.css */

  .not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .not-found-content {
    max-width: 600px;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .not-found-title {
    font-size: 96px;
    font-weight: 700;
    color: #956911;
    margin-bottom: 20px;
  }
  
  .not-found-text {
    font-size: 18px;
    margin-bottom: 30px;
    color: #333;
  }
  
  .not-found-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #956911;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .not-found-link:hover {
    background-color: #956911;
  }
  
  .custom-modal-margin .modal-dialog {
    margin-top: 300px; /* Adjust this value as needed */
  }

  .full-width {
    width: 30% !important;
    padding: 15px;
    border-radius: 5px;
    margin: 0 1%;
    margin-bottom: 2%;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-size: 16px;
    color: #333;
  }
  
  .orderLink{
    color: black;
    text-decoration: none;
  }