.side {
  display: none;
  /* width: 100%;
  height: 100%;
  position: fixed;
  z-index: 3500;
  top: 0;
  left: 0;
  transition: all 0.4s;
  backdrop-filter: blur(8px);
  background-image: linear-gradient(
    rgba(115, 69, 79, 0.3),
    rgba(115, 69, 79, 0.3)
  );
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s; */
}

.search_input{
  flex: 1;
  border: none;
  background: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 50px;
  width: 100%;
}
.search_button{
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.search_bar{
  z-index: 3500;
  display: flex;
  align-items: center;
  width: 60%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff; /* Light gray background */
  border-radius: 50px;
  padding: 1px 15px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */
}

.searchbox {
  z-index: 4000; 
  position: absolute; 
  top: 50px; 
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  max-width: 600px;
  background-color: #ffffff;
  margin-top: 1%;
  border-radius: 30px;
  padding:  5px 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: none;
}

.searchbox_visible {
  display: block;
}

.searchbox .col {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.searchbox img {
  margin-right: 15px;
}

.links {
  background-color: #73454f;
  width: 100%;
  padding: 10px 0 5px 0;
  position: absolute;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 768px) {
   .search_bar {
    display: none;
  }
  
  .searchbox_visible, .searchbox{
width: 70%;
transform: translateX(1%);
top: 50px; 
left: 10%;
margin-top: 4%;
}
  .side {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 3500;
    top: 0;
    left: 0;
    transition: all 0.4s;
    backdrop-filter: blur(8px);
    background-image: linear-gradient(
      rgba(115, 69, 79, 0.3),
      rgba(115, 69, 79, 0.3)
    );
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
  }

}