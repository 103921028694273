.back {
  position: fixed;
  bottom: 80px;
  right: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  /* background-color: #; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  z-index: 15;
  cursor: pointer;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
