.link {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.link:hover {
  color: #fff;
}
