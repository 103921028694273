
    .NavLinks{
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: start;
        padding: 0;   
        margin-top: 1%;
 }

    .nav_link{
        margin-right: 3%;
        font-weight: bold;
        cursor: pointer;
        color: black;
        text-transform: none;
        text-decoration: none;

    }

    .side {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 3500;
        top: 0;
        left: 0;
        transition: all 0.4s;
        backdrop-filter: blur(8px);
        background-image: linear-gradient(
          rgba(115, 69, 79, 0.2),
          rgba(115, 69, 79, 0.2)
        );
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        -ms-transition: all 0.4s;
        -o-transition: all 0.4s;
      }
      
      .links {
        background-color: #fff;
        height: 100vh;
        width: 300px;
        padding: 20px;
        position: absolute;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
      }
      
      .links div {
        margin-top: 40px;
      }
      
   
      .special::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #73454f;
        bottom: 0;
        left: 0;
        opacity: 0.5;
      }
      
      .toggle {
        width: 38px;
        cursor: pointer;
        height: 38px;
        background-color: #fff;
        border: 1px solid #ccc;
        position: absolute;
        right: -19px;
        top: 25px;
        z-index: 4500;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      @media (max-width: 768px) {

      .NavLinks{
        flex-direction: column;
        font-size: larger;
      }

      .nav_link {
        position: relative;
        padding: 15px;
        cursor: pointer;
        display: block;
      }
      
      .nav_link::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #73454f;
        top: 0;
        left: 0;
        opacity: 0.5;
      }
      }