.ball {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
}

.imgDefault  {
  width: 100%; 
  height: auto; 
  display: block; 
  box-sizing: border-box;
  object-fit: fill;
}

.hr {
  width: 100%;
  height: 3px;
  background-color: gray;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.area {
  width: 100%;
  height: 100px;
  resize: none;
  border: 1px solid #ccc;
  margin-top: 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.area:focus {
  width: 100%;
  resize: none;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  margin-top: 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: #f1f1f1 !important;
}

.form .cont {
  width: 48%;
}

.input {
  width: 7% !important;
  resize: none;
  /* border: 1px solid #ccc !important; */
  box-shadow: none !important;
  border-radius: 5px;
  background-color: transparent;
  color: black;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  /* background-color: #f1f1f1 !important; */
}

.input:focus {
  width: 100%;
  resize: none;
  border: 1px solid #ccc !important;
  box-shadow: none !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background-color: #f1f1f1 !important;
}

.quantityBtn{
  font-weight:900;
  font-size: 25px;
  border-radius: 50%;
  padding: 5px 10px;
  border: none;
  color: #956911;
  background-color: transparent;
}

.userImage {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.addCart{
  border-radius: 7px;
  background-color: #956911;
  font-weight: 500;
  width: 30%;
  border:1px solid transparent;
  box-shadow: 0px 3px 5px rgb(0, 0, 0, 0.1);
  padding: 1%;
  color: white;
}
.notify{
  border-radius: 7px;
  background-color: white;
  font-weight: 500;
  width: 30%;
  border:1px solid #956911;
  /* box-shadow: 0px 3px 5px rgb(0, 0, 0, 0.1); */
  padding: 1%;
  color: #956911;

}
 .disabled{
  background-color: #c5a057;

}
.customModal{
  margin-top: 15% !important;
}