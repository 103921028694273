.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  color: #333 !important;
  margin-inline: 15px;
  border-radius: 28px;
  font-weight: bold !important;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  -ms-border-radius: 28px;
  -o-border-radius: 28px;
  transition: all 0.3s !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -ms-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
}

.link:hover,
.link.active {
  background-color: #ffcbd3 !important;
  color: #333 !important;
}

.myimg {
  position: relative;
}

.layer {
  position: absolute;
  inset: 0;
  /* background-color: red; */
}

.hearticon{
  position: absolute;
  top: 0;
  right:0;
  cursor: pointer;
  margin: 17px;
  z-index: 1000;
}

.sold {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 9px;
  font-weight: bold;
  background-color: #f3dade;
  padding: 1px 6px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  text-transform: uppercase;
  letter-spacing: 0px;
}

.eye {
  position: absolute;
  top: 55px;
  right: -50px;
  background-color: #f1f1f1;
  width: 40px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-size: 12px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  cursor: pointer;
}

.myimg:hover .eye {
  right: 15px;
}

.eye:hover {
  background-color: #f3dade !important;
  color: #333;
}

.eye:hover .small {
  opacity: 1;
}

.small {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.eye .small {
  position: absolute;
  left: -60px;
  opacity: 0;
  background-color: #f3dade !important;
  padding: 1px 6px;
  color: #333;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.title {
  color: #f3dade !important;
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: bold;
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.myimg:hover .title {
  opacity: 1;
}

.shopCart {
  position: absolute;
  bottom: -12px;
  right: 0px;
  width: 30px;
  height: 30px;
  background-color: #f3dade;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 10px 9px 10px 7px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  opacity: 0;
}
.myimg:hover .shopCart {
  right: 5px;
  opacity: 1;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right {
  display: flex;
}
.right .circle {
  width: 15px;
  height: 15px;
  margin: 2px;
  background-color: red;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.searchedProducts{
  width: 100%;
  height: 100vh;
}

.badge {
  position: absolute;
  top: 10px; /* Adjust as necessary */
  left: 10px; /* Adjust as necessary */
  background-color: rgba(0, 0, 0, 0.5); /* Change to desired badge background color */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px; /* Adjust font size as necessary */
  z-index: 10; /* Ensure it appears above other elements */
}

.select{
  width: 20%;
  z-index: 10000;
}

.Dropdown{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

/* .newcollection{


} */