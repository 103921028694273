.side {
  width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 3500;
  top: 0;
  left: 0;
  transition: all 0.4s;
  backdrop-filter: blur(8px);
  background-image: linear-gradient(
    rgba(85, 166, 238, 0.37),
    rgba(85, 166, 238, 0.37)
  );
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.links {
  background-color: #fff;
  min-height: 100vh;
  width: 300px;
  padding: 20px;
  position: absolute;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  top: 0;
  right: 0;
}

.toggle {
  width: 38px;
  cursor: pointer;
  height: 38px;
  background-color: #fff;
  border: 1px solid #ccc;
  position: absolute;
  left: -19px;
  top: 25px;
  z-index: 4500;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  background-color: #73454f !important;
  color: white !important;
  text-decoration: none;
}

.checkoutDiv{
  position: relative;
  width: 100%;
  height: 100%;
}
.checkoutBtn{
  background-color: #73454f;
  color: white;
  border: 1px solid #73454f;
  padding: 10px; /* Adjusted for better spacing */
  width: 70%;
  margin: 0 auto; /* Center horizontally */
  border-radius: 10px;
  font-weight: bold;
  position: absolute;
  left: 50%;
  bottom: 6%;
  transform: translateX(-50%); 
}