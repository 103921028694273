.dashboardBtn{
    width: 75%;
    margin:auto;
text-decoration: none;
color: black;
text-align: center;
cursor: pointer;
border: 0;
border-radius: 10px;
background-color: white;
padding: 3%;
margin: 3% 0;
}


.DashboardList{
display: flex;
flex-direction: column;
justify-content: center;
margin: auto;
align-items: center;

}

@media only screen and (max-width: 600px) {
    .dashboardBtn{
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .dashboardBtn{
        width: 100%;
    }
}
