.orderdetails{

    border: 1px solid rgb(224, 219, 219);
    border-radius: 10px;
    padding: 1% 3%;
    font-weight: bold;
    margin: 2%;
}
.orderdata{
    color: #956911;
}