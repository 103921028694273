.layer {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.4);
}
.item{
  width: 100%;
}
.item:hover .layer {
  top: 0;
}
.slider{
  margin-top: 3%;
}
.btn {
  background-color: #f493a6 !important;
}


@media (max-width: 768px) {
  .slider{
    margin-top: -3%;
  }
}